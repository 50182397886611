@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);

body {
  margin: 0;
  font-family: 'Press Start 2P', cursive;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../../static/media/Luna.11eedddf.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  background-color: #14191d;
  line-height: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Minter {
  max-width: 80vh;
  max-height: 80vh;
  padding: 60px 100px;
  margin: 0 auto;
  text-align: center;

}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  font-size: 16px;
  width: 100%;
  border-color: rgba(4, 4, 5, 0.1);
  line-height: 32px;
}

input:focus {
  outline: none;
  border-color: rgba(4, 4, 5, 0.4);
}

button {
  padding: 9px 16px;
  max-height: 40px;
  border-color: #7a7a7a;
  color: #ffffff;
  background-color: rgb(0, 0, 0);
  border-radius: 8px;
  -webkit-align-items: center;
          align-items: center;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  font-weight: bold;
  cursor:pointer;
}

#title {
  padding-top: 5px;
  text-align: center;
  line-height: 50px;
}

#status {
  color:rgb(252, 167, 167);
}

.uploadFile {
  border: 4px dashed rgba(4, 4, 5, 0.1);
  border-radius: 16px;
  text-align: center;
  padding: 32px 60px;
}

#walletButton {
  text-align: center;
  margin-bottom: 10px;
  line-height: 16px;
}


#checkvote {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #000000;
  color:rgb(255, 255, 255);
}

#checkburnrate {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #000000;
  color:rgb(255, 255, 255);
}

#checkwitchrate {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #000000;
  color:rgb(255, 255, 255);
}

#zeropercentburnbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #ffffff;
  color:rgb(0, 0, 0);
}

#onepercentburnbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #f1c889;
  color:rgb(0, 0, 0);
}

#threepercentburnbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #ff6701;
  color:rgb(0, 0, 0);
}

#fivepercentburnbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #fc3a3a;
  color:rgb(12, 12, 12);
}

#tenpercentburnbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #e60101;
  color:rgb(0, 0, 0);
}


#zeropercentwitchbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #ffffff;
  color:rgb(0, 0, 0);
}

#onepercentwitchbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #6fd89b;
  color:rgb(0, 0, 0);
}

#threepercentwitchbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #4cb85e;
  color:rgb(0, 0, 0);
}

#fivepercentwitchbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #0ea02d;
  color:rgb(12, 12, 12);
}

#tenpercentwitchbutton {
  margin-top: 5px;
  margin-bottom: 30px;
  background-color: #026d10;
  color:rgb(0, 0, 0);
}

#burnrateentry {
  max-width: 70px;
}

#proposals {
  max-width: 350px;
}

#checktokenid {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 100px;
}

#webbutton {

}

#currentrewardrate {
  color: #c51616;

}

form {
  margin-left: 35%;
  margin-right:35%;
  width: 30%;
}

#webbutton{

  color: #000000;
}

#pixaburn {
  color: #f19f79;
  line-height: 30px;
}

#pixatransfer {
  color: #f1e579;
  line-height: 30px;
}

#ethtransfer {
  color: #7784f3;
  line-height: 30px;
}

#pixareward {
  color: #79f193;
  line-height: 30px;

}

#pixarewardz {
  color: #50d8e2;
  line-height: 30px;
}
#subheading {
  color: #8a8a8a;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

